<template>
  <div class="row">
    <footer class="text-center">
      <p>
        2023 © Geeks On Code <br />
        **Due to the COVID-19 virus, turn around time on some projects may be
        longer than usual.
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {}
};
</script>
