var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"navigation-top"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2 col-lg-2 col-md-3 col-sm-3 col-4"},[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":{
              path: '/home'
            },"tag":"a"}},[_c('img',{attrs:{"src":"/images/geeks-on-code-logo.png"}})])],1)]),_c('div',{staticClass:"col-xl-8 col-lg-8 col-md-5 col-sm-5 col-4 d-md-block d-sm-block d-xs-block d-lg-none d-xl-none"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"mobile-btn-menu text-center"},[_c('button',{staticClass:"navbar-toggler mobile-menu-btn",attrs:{"type":"button"},on:{"click":function($event){_vm.isShow = !_vm.isShow}}},[_vm._v(" Menu "),_c('i',{staticClass:"fas fa-bars"})])])])]),_c('div',{staticClass:"col-xl-8 col-lg-8 col-md-4 col-sm-4 col-4 d-none d-lg-block d-xl-block"},[_c('div',{staticClass:"top-navigation-center"},[_c('ul',{staticClass:"nav justify-content-center"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link active",attrs:{"to":{
                  path: '/home'
                },"tag":"a"}},[_c('span',{staticClass:"icon-home"}),_vm._v(" Home ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/employees'
                },"tag":"a"}},[_c('span',{staticClass:"icon-empoyees"}),_vm._v(" Employee ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link active",attrs:{"to":{
                  path: '/clients'
                },"tag":"a"}},[_c('span',{staticClass:"icon-clients"}),_vm._v(" Clients ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link active",attrs:{"to":{
                  path: '/projects'
                },"tag":"a"}},[_c('span',{staticClass:"icon-projucts"}),_vm._v(" Projects ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link active",attrs:{"to":{
                  path: '/invoices'
                },"tag":"a"}},[_c('span',{staticClass:"icon-invoices"}),_vm._v(" Invoices ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link active",attrs:{"to":{
                  path: '/messages'
                },"tag":"a"}},[_c('span',{staticClass:"icon-messages"}),_vm._v(" Messages ")])],1),_c('li',{staticClass:"nav-item"},[_vm._m(0),_c('div',{staticClass:"dropdown-menu"},[_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                    path: '/leads'
                  },"tag":"a"}},[_vm._v(" leads ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                    path: '/leads-status'
                  },"tag":"a"}},[_vm._v(" Lead Status ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                    path: '/leads-steps'
                  },"tag":"a"}},[_vm._v(" Lead Steps ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                    path: '/leads-source'
                  },"tag":"a"}},[_vm._v(" Lead source ")])],1)]),_c('li',{staticClass:"nav-item"},[_vm._m(1),_c('div',{staticClass:"dropdown-menu"},[_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                    path: '/user-roles'
                  },"tag":"a"}},[_vm._v(" User Roles ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                    path: '/brands'
                  },"tag":"a"}},[_vm._v(" Brands ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                    path: '/project-status'
                  },"tag":"a"}},[_vm._v(" Project Status ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                    path: '/project-status-category'
                  },"tag":"a"}},[_vm._v(" Project Status Category ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                    path: '/all-notifications'
                  },"tag":"a"}},[_vm._v(" Notifications ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                    path: '/product-services'
                  },"tag":"a"}},[_vm._v(" Product Services ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                    path: '/settings'
                  },"tag":"a"}},[_vm._v(" Settings ")])],1)]),_c('li',{staticClass:"nav-item"},[_vm._m(2),_c('div',{staticClass:"dropdown-menu"},[_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                    path: '/reports/client'
                  },"tag":"a"}},[_vm._v(" Client ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                    path: '/reports/project'
                  },"tag":"a"}},[_vm._v(" Project ")])],1)])])])]),_c('div',{staticClass:"col-xl-2 col-lg-2 col-md-4 col-sm-4 col-4"},[_c('div',{staticClass:"top-right text-right"},[_c('notification'),_c('settingsNav')],1)]),_c('div',{staticClass:"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-md-block d-sm-block d-xs-block d-lg-none d-xl-none"},[_c('div',{staticClass:"container"},[_c('div',{},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isShow)?_c('div',{staticClass:"top-navigation-center Mobile-navigation"},[_c('ul',{staticClass:"nav justify-content-center"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link active",attrs:{"to":{
                        path: '/home'
                      },"tag":"a"}},[_c('span',{staticClass:"icon-home"}),_vm._v(" Home ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                        path: '/employees'
                      },"tag":"a"}},[_c('span',{staticClass:"icon-empoyees"}),_vm._v(" Employee ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link active",attrs:{"to":{
                        path: '/clients'
                      },"tag":"a"}},[_c('span',{staticClass:"icon-clients"}),_vm._v(" Clients ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link active",attrs:{"to":{
                        path: '/projects'
                      },"tag":"a"}},[_c('span',{staticClass:"icon-projucts"}),_vm._v(" Projects ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link active",attrs:{"to":{
                        path: '/invoices'
                      },"tag":"a"}},[_c('span',{staticClass:"icon-invoices"}),_vm._v(" Invoices ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link active",attrs:{"to":{
                        path: '/messages'
                      },"tag":"a"}},[_c('span',{staticClass:"icon-messages"}),_vm._v(" Messages ")])],1),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"data-toggle":"dropdown","href":"#"}},[_c('span',{staticClass:"fa-crosshairs fa-fw fa"}),_vm._v(" Leads")]),_c('div',{staticClass:"dropdown-menu"},[_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                          path: '/leads'
                        },"tag":"a"}},[_vm._v(" Leads ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                          path: '/leads-status'
                        },"tag":"a"}},[_vm._v(" Lead Status ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                          path: '/leads-steps'
                        },"tag":"a"}},[_vm._v(" Lead Steps ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                          path: '/leads-source'
                        },"tag":"a"}},[_vm._v(" Lead Source ")])],1)]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"data-toggle":"dropdown","href":"#"}},[_c('span',{staticClass:"icon-settings"}),_vm._v(" Others")]),_c('div',{staticClass:"dropdown-menu"},[_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                          path: '/user-roles'
                        },"tag":"a"}},[_vm._v(" User Roles ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                          path: '/brands'
                        },"tag":"a"}},[_vm._v(" Brands ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                          path: '/settings'
                        },"tag":"a"}},[_vm._v(" Company Settings ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                          path: '/project-status'
                        },"tag":"a"}},[_vm._v(" Project Status ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                          path: '/project-status-category'
                        },"tag":"a"}},[_vm._v(" Project Status Category ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                          path: '/all-notifications'
                        },"tag":"a"}},[_vm._v(" All Notifications ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                          path: '/product-services'
                        },"tag":"a"}},[_vm._v(" Product Services ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                          path: '/settings'
                        },"tag":"a"}},[_vm._v(" Settings ")])],1)]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"data-toggle":"dropdown","href":"#"}},[_c('span',{staticClass:"icon-settings"}),_vm._v(" Reports By")]),_c('div',{staticClass:"dropdown-menu"},[_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                          path: '/reports/client'
                        },"tag":"a"}},[_vm._v(" Client ")]),_c('router-link',{staticClass:"nav-link dropdown-item",attrs:{"to":{
                          path: '/reports/project'
                        },"tag":"a"}},[_vm._v(" Project ")])],1)])])]):_vm._e()])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"data-toggle":"dropdown","href":"#"}},[_c('span',{staticClass:"fa-crosshairs fa-fw fa"}),_vm._v(" Leads")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"data-toggle":"dropdown","href":"#"}},[_c('span',{staticClass:"icon-settings"}),_vm._v(" Others")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"data-toggle":"dropdown","href":"#"}},[_c('span',{staticClass:"fa-crosshairs fa-fw fa"}),_vm._v(" Report By")])
}]

export { render, staticRenderFns }